import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'

const NotFoundPage = () => (
  <Layout error404={true}>
    <SEO title="404: Not found" />

    <Section className="mb-5">
      <Container>
        <Row>
          <Col>
            <h1>Page Not Found</h1>
            <p>
              The resource you are trying to access doesn’t exist or has been
              moved.
            </p>
            <p>
              You can return to the <Link to="/">home page</Link> or contact us
              if you can’t find what you are looking for.
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
